var generic = generic || {};
var site = site || {};
var MantleMultiLang = MantleMultiLang || {};

(function ($, Drupal) {
  /*
   * Register a locale switch validator that opens an overlay if there are
   * items in cart.
   */
  MantleMultiLang.registerSwitchValidator('items_in_cart', function (target_locale) {
    var current_locale = MantleMultiLang.getCurrentLocale();
    var current_settings = MantleMultiLang.getLocaleSettings(current_locale);
    var target_settings = MantleMultiLang.getLocaleSettings(target_locale);

    // Need valid settings to do anything.
    if (!current_settings || !target_settings) {
      return;
    }

    // locales within the same region don't need to recalc anything.
    if (current_settings.region === target_settings.region) {
      return;
    }

    var valueKey = 'item_count';

    // reset cookie
    site.userInfoCookie.set();
    var itemsTotal = site.userInfoCookie.getValue(valueKey) - 0 || 0;

    // Only validate when items are in cart.
    if (itemsTotal === 0) {
      return;
    }

    var deferred = $.Deferred();

    Drupal.behaviors.countrySelectionPopupV1.popOverlay(target_locale, current_locale, deferred);

    var promise = deferred.promise();

    return promise;
  });

  Drupal.behaviors.countrySelectionPopupV1 = {
    popOverlay: function (target_locale, current_locale, deferred) {
      var rendered = site.template.get({
        name: 'country_selection_popup',
        data: {}
      });

      generic.overlay.launch({
        content: rendered,
        width: '100%',
        maxWidth: 570,
        height: 380,
        cssClass: 'country-selection-popup-cbox',
        onComplete: function () {
          var $content = $('#colorbox .js-country-selection-popup');
          var $switchLink = $('.js-switch-link', $content);
          var $closeLink = $('.js-close-link', $content);
          var target_settings = MantleMultiLang.getLocaleSettings(target_locale);
          var current_settings = MantleMultiLang.getLocaleSettings(current_locale);
          var target_label = target_settings.region_label;
          var current_label = current_settings.region_label;
          var target_show_the_prefix = !!target_settings.prefixed_the;
          var current_show_the_prefix = !!current_settings.prefixed_the;

          // change to proper region names
          $('.js-target-region', $content).html(target_label);
          $('.js-current-region', $content).html(current_label);

          // Some languages need "the" prefix
          if (current_show_the_prefix) {
            $('.js-current-the-prefix', $content).show();
          } else {
            $('.js-current-the-prefix', $content).hide();
          }

          if (target_show_the_prefix) {
            $('.js-target-the-prefix', $content).show();
          } else {
            $('.js-target-the-prefix', $content).hide();
          }

          $switchLink.on('click', function (e) {
            e.preventDefault();
            // Allow switching of locales.
            deferred.resolve(true);
          });

          $closeLink.on('click', function (e) {
            e.preventDefault();
            $.colorbox.close();
          });
        },
        onClosed: function () {
          // don't swtich locales
          deferred.resolve(false);
        }
      });
    },

    attach: function () {
      // Empty.
    }
  };
})(jQuery, Drupal);
